import { State } from "./types"

/**
 * 
 * Initial state of the search module
 * 
 */

const initialState: State = {
  status: "idle",
  params: {
    spatial: [
      { lat: -180, lng: 90 },
      { lat: -180, lng: -90 },
      { lat: 180, lng: -90 },
      { lat: 180, lng: 90 },
      { lat: -180, lng: 90 },
    ],
    time: {
      start: new Date(Date.now() - 604800000),
      end: new Date()
    }
  },
  dataset: null,
  // error: null,
}

export default initialState