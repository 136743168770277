import React from "react"
import "mapbox-gl/dist/mapbox-gl.css"

import { useSearchContext } from "marketplace/search/context/SearchContext"

import useMap from "./hooks/useMap"

import {
  ErrorPanel,
  LoadingCover,
  LoadingCoverAnime,
} from "marketplace/app/components"

import {
  MapControls,
  BaseMap,
  HeatmapLayer,
  ExplorationControls,
  DrawControls,
} from "./components"

const Map = ({ noData, setNoData, children }) => {
  const {
    status,
    searchParams,
    resetFromMap,
    updateSpatialParam,
  } = useSearchContext()

  const {
    mapRef,
    viewport,
    viewportHandler,
    fetchingPreview,
    dataPreview,
    errorPreview,
    initialLoad,
    setIsDrawing,
    editorRef,
    previewSpatialParam,
    flyToWorld,
  } = useMap({ status, resetFromMap, searchParams, updateSpatialParam })

  React.useEffect(() => {
    const checkData = dataPreview?.geoJson?.features?.length > 0
    // Change when fixing
    setNoData(false)
    /*if (checkData && noData) {
      setNoData(false)
    } else if (!checkData && !noData) {
      setNoData(true)
    }*/
  }, [dataPreview])

  if (errorPreview) return <ErrorPanel error={errorPreview} />

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      {fetchingPreview && !initialLoad && <LoadingCoverAnime />}
      {fetchingPreview && initialLoad && (
        <LoadingCover transparency={initialLoad} />
      )}
      {/* {fetchingPreview && <LoadingCover animation={initialLoad} />} */}
      {status === "idle" && (
        <ExplorationControls
          viewport={viewport}
          viewportHandler={viewportHandler}
          resetHandler={flyToWorld}
          // timeQueryParam={timeQueryParam}
          // timeQueryParamHandler={updateTimeQueryParam}
        />
      )}
      {children}
      <BaseMap
        mapRef={mapRef}
        viewport={viewport}
        viewportHandler={viewportHandler}
      >
        {status === "idle" && <MapControls />}

        {status === "idle" && (
          <DrawControls
            setIsDrawing={setIsDrawing}
            editorRef={editorRef}
            previewSpatialParam={previewSpatialParam}
            // spatialDatasetQueryParamHandler={updateSpatialDatasetQueryParam}
            // resetSpatialDatasetQueryParamHandler={resetSpatialDatasetQueryParam}
          />
        )}

        {dataPreview && dataPreview.geoJson.features && (
          <HeatmapLayer data={dataPreview} />
        )}
      </BaseMap>
    </div>
  )
}

export default Map
