import React, { useEffect } from "react"

import { useSearchContext } from "marketplace/search/context/SearchContext"
import Geocoder from "./geocoder/Geocoder"
import DateRangePicker from "./daterange/DateRangePicker"
import { Flex, Icon, Text } from "marketplace/app/components"
import CalendarImg from "marketplace/app/images/icons/calendar.svg"

const MONTHS_ABBREVIATIONS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

function formatDateToDisplay(date) {
  const month = MONTHS_ABBREVIATIONS[date.getMonth()]
  const day = date.getDate()
  const year = `${date.getFullYear()}`.substr(2)
  return `${month} ${day}/${year}`
}

const ExplorationControls = ({ viewport, viewportHandler, resetHandler }) => {
  const { searchParams } = useSearchContext()
  const { time: timeParam } = searchParams

  const [calendarOpen, setCalendarOpen] = React.useState(false)
  const [calendarFlag, setCalendarFlag] = React.useState(false)

  useEffect(() => {
    if (!calendarOpen && calendarFlag) {
      setTimeout(() => {
        setCalendarFlag(false)
      }, 500)
    }
  }, [calendarOpen, calendarFlag])

  const openCalendar = () => {
    if (!calendarFlag) {
      setCalendarOpen(true)
      setCalendarFlag(true)
    }
  }

  const closeCalendar = () => {
    setCalendarOpen(false)
  }

  return (
    <Flex sx={{ position: "relative" }}>
      <Flex
        // column
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "100%",
          top: "20px",
        }}
      >
        <Flex
          sx={{
            flex: 1,
            mx: ["10px", "20px", "20px", "20px"],
          }}
        >
          <Flex
            sx={{
              flex: 1,
              justifyContent: "center",
              mx: "auto",
            }}
          >
            {/* Geocoder */}
            <Flex
              sx={{
                flex: 1,
                height: "80px",
                maxWidth: "404px",
                bg: "background",
                borderTop: "1px solid",
                borderTopColor: "primary",
                borderBottom: "1px solid",
                borderBottomColor: "primary",
                borderLeft: "1px solid",
                borderLeftColor: "primary",
                borderRadius: "8px 0px 0px 8px",
              }}
            >
              <Geocoder
                viewport={viewport}
                viewportHandler={viewportHandler}
                resetHandler={resetHandler}
              />
            </Flex>
            {/* Calendar */}
            <Flex
              sx={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                width: "276px",
                maxWidth: "276px",
                minHeight: "80px",
                height: "auto",
                bg: "background",
                border: "1px solid",
                borderColor: "primary",
                borderRadius: calendarOpen
                  ? "0px 8px 8px 8px"
                  : "0px 8px 8px 0px",
              }}
            >
              <Flex
                onClick={openCalendar}
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "80px",
                  mx: ["12px", "24px", "24px", "24px"],
                }}
              >
                <Flex>
                  {timeParam ? (
                    <Text variant="text.dateRange">
                      <span style={{ display: "inline-block" }}>
                        {formatDateToDisplay(timeParam.start)}
                      </span>
                      <span style={{ marginLeft: "6px", marginRight: "6px" }}>
                        -
                      </span>
                      <span style={{ display: "inline-block" }}>
                        {formatDateToDisplay(timeParam.end)}
                      </span>
                    </Text>
                  ) : (
                    <Text variant="text.textInput">Date range</Text>
                  )}
                </Flex>
                <Flex>
                  <Icon
                    path={CalendarImg}
                    title="calendar"
                    sx={{ width: "24px", height: "24px", p: "2px" }}
                  />
                </Flex>
              </Flex>
              <Flex
                sx={{
                  borderTop: calendarOpen && "1px solid",
                  borderTopColor: calendarOpen && "primary",
                  pt: calendarOpen && "16px",
                  pb: calendarOpen && "25px",
                  mx: "24px",
                }}
              >
                <DateRangePicker
                  isOpen={calendarOpen}
                  onClose={closeCalendar}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default React.memo(ExplorationControls)
