import React, { useEffect } from "react"
import Geocoder from "react-mapbox-gl-geocoder"
import { FlyToInterpolator } from "react-map-gl"

import GeocoderInput from "./GeocoderInput"

import { Flex, Icon, ButtonIcon } from "marketplace/app/components"
import SearchImg from "marketplace/app/images/icons/search.svg"
import CloseImg from "marketplace/app/images/icons/close.svg"

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN

const GEOCODER_CUSTOM_STYLES = {
  // custom wrapper
  flex: 1,
  flexFlow: "column",
  height: "100%",
  alignItems: "center",
  // geocoder css classes - overwrite
  [".react-geocoder"]: {
    flex: "1 1 auto",
    verticalAlign: "middle",
    display: "grid",
    // width: "auto"
    width: "fill-available",
    // p: "4px",
  },
  [".react-geocoder input"]: {
    variant: "text.textInput",
    // flex: "1 1 auto",
    border: "none",
    bg: "background",
    // width: "auto",
    // px: "24px",
    // width: "fill-available",
    // height: "fill-available",
    m: "1px",
    px: ["10px", "20px", "20px", "20px"],
    borderRadius: "8px 0px 0px 8px",
    height: "75px",
    maxHeight: "75px",
    minHeight: "75px",
    // height: "100%",
    // minHeight: "70px",
  },
  [".react-geocoder-results"]: {
    variant: "text.textSmall",
    bg: "background",
    flex: 1,
    // flex: "1 1 auto",
    // verticalAlign: "middle",
    width: "100%",
    maxWidth: "405px",

    pt: "20px",
    pb: "12px",
    px: ["17px", "22px", "22px", "22px"],
    mt: "-8px",
    ml: "-1px",
    cursor: "pointer",
    // p: "24px",
    // mt: "20px",
    // display: "grid",
    // borderRadius: ["7px", "0px", "0px", "7px"],

    // borderTop: "1px solid",
    // borderTopColor: "primary",
    borderRight: "1px solid",
    borderRightColor: "primary",
    borderBottom: "1px solid",
    borderBottomColor: "primary",
    borderLeft: "1px solid",
    borderLeftColor: "primary",
    borderRadius: "0px 0px 8px 8px",
  },
  [".react-geocoder-item"]: {
    // variant: "text.textSmall",
    py: "10px",
    "&:hover": {
      bg: "muted",
    },
  },
}

const flyToSelectionOptions = {
  zoom: 7.5,
  transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
  transitionDuration: "auto",
}

const GeocoderWrap = ({ viewport, viewportHandler, resetHandler }) => {
  const [isActive, setIsActive] = React.useState(false)
  const [isReseting, setIsReseting] = React.useState("")

  useEffect(() => {
    if (!isActive && isReseting) {
      setIsReseting("")
    }
  }, [isActive, isReseting])

  const geocoderHandler = (newViewport, item) => {
    setIsActive(true)
    viewportHandler({
      ...newViewport,
      ...flyToSelectionOptions,
    })
  }

  const onReset = () => {
    setIsReseting("reset")
    setIsActive(false)
    resetHandler()
  }

  const GeocoderInputWrap = props => {
    return <GeocoderInput {...props} resetflag={isReseting} />
  }

  return (
    <Flex
      sx={{
        flex: 1,
        // m: "2px",
        // px: ["10px","20px","20px","20px"],
        justifyContent: "space-between",
      }}
    >
      {/* <> */}
      <Flex sx={GEOCODER_CUSTOM_STYLES}>
        <Geocoder
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onSelected={geocoderHandler}
          viewport={viewport}
          hideOnSelect={true}
          updateInputOnSelect={true}
          inputComponent={GeocoderInputWrap}
        />
      </Flex>
      <Flex sx={{ alignItems: "center" }}>
        {isActive ? (
          <ButtonIcon
            src={CloseImg}
            onClick={onReset}
            alt="Reset"
            title="Reset"
            sx={{ width: "24px", pr: ["10px", "20px", "20px", "20px"] }}
          />
        ) : (
          <Icon
            path={SearchImg}
            title="search"
            sx={{
              width: "24px",
              height: "24px",
              // p: "2px",
              pr: ["10px", "20px", "20px", "20px"],
            }}
          />
        )}
      </Flex>
      {/* </> */}
    </Flex>
  )
}

export default GeocoderWrap
