/**
 * 
 */
 export class EstimatedPointsError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, EstimatedPointsError)
    }

    this.name = "EstimatedPointsError"
    this.date = new Date()

    this.type = type

    this.message = "There was a problem requesting the estimated price of the dataset of interest."
  }
}