export function getWeightConfig(stats) {
  const baseConfig = ["interpolate", ["linear"], ["get", "weight"]]
  if (stats.min === 1) {
    return [...baseConfig, 0, 0, 1, 1]
  } else {
    return [...baseConfig, stats.min, stats.p90, stats.p99, stats.max]
  }
}

// palette: monocolor | bicolor

export const getColorConfig = (stats, palette = "monocolor") => {
  const { p99, p95, p90, min, max } = stats
  const baseConfig = ["interpolate", ["linear"], ["heatmap-density"]]

  if (p99 < 0.5) {
    // console.log("__________ layer config >>>> CASE 1")
    return [
      ...baseConfig,
      0,
      "rgba(0,72,255,0)",
      stats.min,
      "rgba(0,72,255,0.1)",
      stats.p95,
      "rgba(0,72,255,0.2)",
      stats.p99,
      "rgba(0,72,255,0.3)",
      // 0.8,
      // "rgba(255,96,123, 0.8)",
      1,
      // "rgb(0,72,255)",
      palette === "monocolor" ? "rgb(0,72,255)" : "rgb(255,96,123)",
    ]
  }

  if (p99 === max && p99 === p95) {
    // console.log("__________ layer config >>>> CASE 4")
    return [
      ...baseConfig,
      0,
      "rgba(0,72,255,0)",
      stats.min,
      "rgba(0,72,255,0.1)",
      1,
      palette === "monocolor" ? "rgb(0,72,255)" : "rgb(255,96,123)",
      // "rgb(255,96,123)",
      // "rgb(0,72,255)",
    ]
  }

  if (p99 === max) {
    // console.log("__________ layer config >>>> CASE 2")
    return [
      ...baseConfig,
      0,
      "rgba(0,72,255,0)",
      stats.min,
      "rgba(0,72,255,0.1)",
      stats.p90,
      // stats.p95,
      "rgba(0,72,255,0.4)",
      stats.p95,
      // stats.p97,
      palette === "monocolor" ? "rgb(0,72,255)" : "rgb(255,96,123)",
      // "rgb(255,96,123)",
      // "rgb(0,72,255)",
    ]
  }

  // console.log("__________ layer config >>>> CASE 3")
  return [
    ...baseConfig,
    0,
    "rgba(0,72,255,0)",
    stats.min,
    "rgba(0,72,255,0.1)",
    // stats.p95,
    // "rgba(0,72,255,0.3)",
    stats.p95,
    "rgba(0,72,255,0.4)",
    stats.max,
    // stats.p99,
    palette === "monocolor" ? "rgb(0,72,255)" : "rgb(255,96,123)",
    // "rgb(255,96,123)",
    // "rgb(0,72,255)",
  ]
}
