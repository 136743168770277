import React from "react"

import { PageView } from "marketplace/app/components"
import Search from "marketplace/search"

const CONTENT = {
  seo: "Search Datasets",
  title: "Create a query and receive metadata and budget",
  description:
    "Adjust in the visible area of ​​the map the geographical area from which you want to buy the data, as well as the interval of days.",
}

const SearchPage = () => (
  <PageView
    seo={CONTENT.seo}
    title={CONTENT.title}
    description={CONTENT.description}
  >
    <Search />
  </PageView>
)

export default SearchPage
