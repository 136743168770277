import { Point, Shape } from "../../context/types"

type DataType = "heatmap" | "dataset"

type Bounds = {
  _ne: Point,
  _sw: Point
}

type BBox = {
  lat1: number
  lng1: number
  lat2: number
  lng2: number
}

export const getSpatialParamsFromMapBounds = (target: DataType, bounds: Bounds): BBox | Shape => {
  const lat1 = bounds._ne.lat
  const lng1 = bounds._ne.lng
  const lat2 = bounds._sw.lat
  const lng2 = bounds._sw.lng

  if (target === "heatmap") {
    return { lat1, lng1, lat2, lng2 }
  }

  if (target === "dataset") {
    return [
      { lat: lat1, lng: lng2 },
      { lat: lat2, lng: lng2 },
      { lat: lat2, lng: lng1 },
      { lat: lat1, lng: lng1 },
      { lat: lat1, lng: lng2 }, // close shape duplicating first point
    ]
  }
}


export const checkResetBounds = (bounds: Bounds): boolean => {
  return bounds._sw.lng <= -180 || bounds._ne.lng >= 180
}
