import { getWeightConfig, getColorConfig } from "./utils"

const MAX_ZOOM_LEVEL = 3.49

const heatmapGlobal = stats => {
  return {
    maxzoom: MAX_ZOOM_LEVEL,
    type: "heatmap",
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      "heatmap-weight": getWeightConfig(stats),

      // DISABLED - Because we dynamically update data when zooming
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      // "heatmap-intensity": [
      //   "interpolate",
      //   ["linear"],
      //   ["zoom"],
      //   0,
      //   1,
      //   MAX_ZOOM_LEVEL,
      //   1.5,
      // ],

      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      "heatmap-color": getColorConfig(stats),

      // Adjust the heatmap radius by zoom level
      "heatmap-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        2,
        MAX_ZOOM_LEVEL,
        70,
      ],

      // Transition from heatmap to circle layer by zoom level
      // "heatmap-opacity": ["interpolate", ["linear"], ["zoom"], 7, 1, 9, 0],
    },
  }
}

export default heatmapGlobal
