import React from "react"
import {
  Editor,
  DrawPolygonMode,
  DrawRectangleMode,
  DrawCircleFromCenterMode,
  EditingMode,
  RENDER_STATE,
} from "react-map-gl-draw"
// import { BiShapePolygon } from "@react-icons/all-files/bi/BiShapePolygon"
// import { BiShapeSquare } from "@react-icons/all-files/bi/BiShapeSquare"
// import { BiShapeCircle } from "@react-icons/all-files/bi/BiShapeCircle"

import { useWallet } from "marketplace/web3"
import { useSearchContext } from "marketplace/search/context/SearchContext"

import circleIcon from "marketplace/app/images/icons/draw-button-circle.svg"
import circleIconSelected from "marketplace/app/images/icons/draw-button-circle-selected.svg"
import squareIcon from "marketplace/app/images/icons/draw-button-square.svg"
import squareIconSelected from "marketplace/app/images/icons/draw-button-square-selected.svg"
import lineIcon from "marketplace/app/images/icons/draw-button-line.svg"
import lineIconSelected from "marketplace/app/images/icons/draw-button-line-selected.svg"
import removeIcon from "marketplace/app/images/icons/draw-remove.svg"

// import rectangleIcon from "marketplace/app/images/icons/Rectangle.svg"

function prepareSpatialDatasetQueryParamFromDraw(drawData) {
  return drawData.map(point => ({
    lat: point[1],
    lng: point[0],
  }))
}

function getEditHandleStyle({ feature, state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 7,
      }

    default:
      return {
        fill: "rgb(251, 176, 59)",
        fillOpacity: 1,
        stroke: "rgb(255, 255, 255)",
        strokeWidth: 2,
        r: 5,
      }
  }
}

function getFeatureStyle({ feature, index, state }) {
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: "rgb(251, 176, 59)",
        strokeWidth: 2,
        fill: "rgb(251, 176, 59)",
        fillOpacity: 0.3,
        strokeDasharray: "4,2",
      }

    default:
      return {
        stroke: "#ff607b",
        strokeWidth: 2,
        fill: "#ff607b",
        fillOpacity: 0.1,
      }
  }
}

export function DrawControls({ setIsDrawing, editorRef, previewSpatialParam }) {
  const { updateSpatialParam } = useSearchContext()
  const { active } = useWallet()

  const [mode, setMode] = React.useState(null)
  const [modeStr, setModeStr] = React.useState("")
  const [selectedFeatureIndex, setSelectedFeatureIndex] = React.useState(null)

  const features =
    editorRef && editorRef.current && editorRef.current.getFeatures()
  const selectedFeature = features && features[selectedFeatureIndex]

  const onSelect = React.useCallback(options => {
    setSelectedFeatureIndex(options && options.selectedFeatureIndex)
  }, [])

  const [reset, setReset] = React.useState(false)

  React.useEffect(() => {
    if (reset) {
      resetMode()
    }
  }, [reset])

  const onDelete = React.useCallback(() => {
    if (selectedFeatureIndex !== null && selectedFeatureIndex >= 0) {
      editorRef.current.deleteFeatures(selectedFeatureIndex)
      // TODO: change with UPDATE_SPATIAL_PARAM
      updateSpatialParam(previewSpatialParam)
      setReset(true)
    }
  }, [selectedFeatureIndex])

  const onUpdate = React.useCallback(({ data, editType }) => {
    if (editType === "addFeature") {
      setMode(new EditingMode())
      setIsDrawing(false)
      //
      const drawData = data[0].geometry.coordinates[0]
      const dataPrepared = prepareSpatialDatasetQueryParamFromDraw(drawData)
      updateSpatialParam(dataPrepared)
    }
  }, [])

  const resetMode = () => {
    setMode(null)
    setModeStr("")
    setSelectedFeatureIndex(null)
    setReset(false)
  }

  const startDrawingHandler = shape => {
    if (shape === "polygon") {
      if (modeStr === shape) return resetMode()
      setMode(new DrawPolygonMode())
      setModeStr(shape)
    }
    if (shape === "rectangle") {
      if (modeStr === shape) return resetMode()
      setMode(new DrawRectangleMode())
      setModeStr(shape)
    }
    if (shape === "circle") {
      if (modeStr === shape) return resetMode()
      setMode(new DrawCircleFromCenterMode())
      setModeStr(shape)
    }
    setIsDrawing(true)
  }

  const isCurrentMode = btn => btn === modeStr

  const featureExist = features && features.length
  const isButtonDisabled = !active || featureExist > 0

  return (
    <>
      {/* Editor */}
      <Editor
        ref={editorRef}
        style={{ width: "100%", height: "100%" }}
        clickRadius={12}
        mode={mode}
        onSelect={onSelect}
        onUpdate={onUpdate}
        editHandleShape={"circle"}
        featureStyle={getFeatureStyle}
        editHandleStyle={getEditHandleStyle}
      />
      {/* Draw Controls */}
      <div
        className="mapboxgl-ctrl-bottom-right"
        style={{
          bottom: "155px",
          right: "20px",
          zIndex: "unset",
        }}
      >
        <div className="mapboxgl-ctrl-group mapboxgl-ctrl no-default-style">
          <button
            className="mapbox-gl-draw_ctrl-draw-btn custom-draw-btn"
            title="Draw Circle"
            onClick={() => startDrawingHandler("circle")}
            disabled={isButtonDisabled}
            style={{
              opacity: isButtonDisabled ? 0.4 : featureExist > 0 ? 0.4 : 1,
              marginBottom: "8px",
              borderRadius: "50%",
              backgroundColor: isCurrentMode("circle") ? "#0048ff" : "#ffffff",
            }}
          >
            <img
              src={isCurrentMode("circle") ? circleIconSelected : circleIcon}
              alt=""
              width="18"
              style={{
                opacity: isButtonDisabled ? 0.4 : featureExist > 0 ? 0.4 : 1,
                verticalAlign: "middle",
              }}
            />
          </button>
          <button
            className="mapbox-gl-draw_ctrl-draw-btn custom-draw-btn"
            title="Draw Rectangle"
            onClick={() => startDrawingHandler("rectangle")}
            disabled={isButtonDisabled}
            style={{
              opacity: isButtonDisabled ? 0.4 : featureExist > 0 ? 0.4 : 1,
              marginBottom: "8px",
              borderRadius: "8px",
              backgroundColor: isCurrentMode("rectangle")
                ? "#0048ff"
                : "#ffffff",
            }}
          >
            <img
              src={isCurrentMode("rectangle") ? squareIconSelected : squareIcon}
              alt=""
              width="18"
              style={{
                opacity: isButtonDisabled ? 0.4 : featureExist > 0 ? 0.4 : 1,
                verticalAlign: "middle",
              }}
            />
          </button>

          <button
            className="mapbox-gl-draw_ctrl-draw-btn custom-draw-btn"
            title="Draw Polygon"
            onClick={() => startDrawingHandler("polygon")}
            disabled={isButtonDisabled}
            style={{
              opacity: isButtonDisabled ? 0.4 : featureExist > 0 ? 0.4 : 1,
              marginBottom: "8px",
              backgroundColor: isCurrentMode("polygon") ? "#0048ff" : "#ffffff",
            }}
          >
            <img
              src={isCurrentMode("polygon") ? lineIconSelected : lineIcon}
              alt=""
              width="18"
              style={{
                verticalAlign: "middle",
                opacity: featureExist > 0 ? 0.4 : 1,
              }}
            />
          </button>
          <button
            className="mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash custom-remove-btn"
            title="Delete"
            onClick={onDelete}
            disabled={!selectedFeature}
            style={{ opacity: !selectedFeature ? 0.3 : 1 }}
          >
            <img
              src={removeIcon}
              alt=""
              width="32"
              style={{
                verticalAlign: "middle",
              }}
            />
          </button>
        </div>
      </div>
    </>
  )
}

export default React.memo(DrawControls)
