import React from "react"

// import DateRangePicker from "@wojtekmaj/react-daterange-picker"
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle"
import "./DateRangePicker.css"

import { useSearchContext } from "marketplace/search/context/SearchContext"
import { Icon } from "marketplace/app/components"
import CalendarImg from "marketplace/app/images/icons/calendar.svg"
import LeftImg from "marketplace/app/images/icons/arrow-left.svg"
import RightImg from "marketplace/app/images/icons/arrow-right.svg"

const DateRangePickerWrapper = ({ isOpen, onClose }) => {
  const { searchParams, updateTimeParam } = useSearchContext()

  const dateRange = [searchParams.time.start, searchParams.time.end]

  const changeHandler = newRange => {
    updateTimeParam({
      start: newRange[0],
      end: newRange[1],
    })
  }

  return (
    <DateRangePicker
      value={dateRange}
      onChange={changeHandler}
      calendarIcon={<Icon path={CalendarImg} />}
      // format="MMM-dd/y"
      isOpen={isOpen}
      onCalendarClose={onClose}
      //
      calendarType="US" // default is ISO 8601
      prevLabel={<Icon path={LeftImg} />}
      nextLabel={<Icon path={RightImg} />}
      maxDate={new Date()}
      minDate={new Date(2021, 0, 1)} // TODO: check min date for production data
    />
  )
}

export default DateRangePickerWrapper
