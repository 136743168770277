import React from "react"
import { ErrorBoundary } from "react-error-boundary"

import { useWallet } from "marketplace/web3"
import { SearchProvider, useSearchContext } from "./context/SearchContext"

import {
  Map,
  Form,
  Loading,
  Summary,
  AddedToCart,
  // Error,
} from "./components"
import { ErrorPanel, Flex } from "marketplace/app/components"

const Search = () => {
  const { resetWalletError } = useWallet()

  const { status, error } = useSearchContext()

  const [noData, setNoData] = React.useState(true)

  return (
    <ErrorBoundary FallbackComponent={ErrorPanel} onReset={resetWalletError}>
      <Flex
        sx={{
          position: "relative",
          minHeight: ["500px", "550px", "600px", "790px"],
          height: "auto",
        }}
      >
        {/* <div style={{ position: "relative", minHeight: "790px", height: "fit-content" }}> */}
        {status === "idle" && <Form noData={noData} />}
        {status === "pending" && <Loading />}
        {status === "summary" && <Summary />}
        {status === "confirmed" && <AddedToCart />}
        {status === "error" && <ErrorPanel error={error} />}
        <Map noData={noData} setNoData={setNoData} />
      </Flex>
    </ErrorBoundary>
  )
}

export default function () {
  return (
    <SearchProvider>
      <Search />
    </SearchProvider>
  )
}
