import React from "react"

import { Flex, PriceLabel, Text } from "marketplace/app/components"
import { numberWithCommas } from "marketplace/app/utils"

const DAYS_WEEK = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const MONTHS_ABBREVIATIONS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]

function formatSummaryDate(date) {
  const dayRaw = date.getDate()
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw
  const dayWeek = DAYS_WEEK[date.getDay()]
  const month = MONTHS_ABBREVIATIONS[date.getMonth()]
  const year = date.getFullYear()
  return `${dayWeek}. ${month}. ${day} ${year}`
}

function formatDateRange(date) {
  const monthRaw = date.getMonth() + 1
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw
  const dayRaw = date.getDate()
  const day = dayRaw < 10 ? `0${dayRaw}` : dayRaw
  const year = `${date.getFullYear()}`.substr(2)
  return `${year}. ${month}. ${day}`
}

const priceStyle = {
  fontFamily: "heading",
  color: "primary",
  fontSize: "20px !important",
  lineHeight: "24px !important",
  alignSelf: "flex-end",
  textAlign: "end",
}

const SummaryTable = ({ dataset }) => {
  const dateRange = (
    <>
      <span style={{ display: "inline-block" }}>
        {formatDateRange(dataset.time.start)}
      </span>
      {" / "}
      <span style={{ display: "inline-block" }}>
        {formatDateRange(dataset.time.end)}
      </span>
    </>
  )
  const numPoints = numberWithCommas(dataset.grossPoints)
  const summaryDate = formatSummaryDate(dataset.summaryDate)

  return (
    <Flex
      column
      sx={{
        maxHeight: "330px",
        overflowY: "auto",
      }}
    >
      <SummaryRow label="Price">
        <PriceLabel price={dataset.grossPrice} sxInt={priceStyle} />
      </SummaryRow>
      <SummaryRow label="Summary date">
        <SummaryValue>{summaryDate}</SummaryValue>
      </SummaryRow>
      <SummaryRow label="Date range">
        <SummaryValue>{dateRange}</SummaryValue>
      </SummaryRow>
      <SummaryRow label="Num points">
        <SummaryValue>{numPoints}</SummaryValue>
      </SummaryRow>
      <SummaryRow label="Attributes">
        <SummaryValue>{"Lat/Lng, Device, etc."}</SummaryValue>
      </SummaryRow>
    </Flex>
  )
}

export default SummaryTable

const valueStyle = {
  fontFamily: "slim",
  textAlign: "end",
}

const SummaryValue = ({ children }) => (
  <Text variant={"text.textSummary"} sx={valueStyle}>
    {children}
  </Text>
)

const SummaryRow = ({ label, underline = true, children }) => {
  return (
    <Flex column>
      <Flex sx={{ py: "7px" }}>
        <Flex sx={{ flex: 1 }}>
          <Text variant="text.textSummary" sx={{ fontFamily: "heading" }}>
            {label}
          </Text>
        </Flex>
        <Flex sx={{ flex: 1, justifyContent: "flex-end" }}>{children}</Flex>
      </Flex>
      {underline && (
        <Flex
          sx={{
            flex: 1,
            borderTop: "1px solid",
            borderTopColor: "text",
            opacity: 0.3,
            mb: "7px",
          }}
        />
      )}
    </Flex>
  )
}
