import React from "react"

import { Button, Text } from "marketplace/app/components"

const QueryButton = ({ disabled = false, clickHandler }) => {
  return (
    <Button
      disabled={disabled}
      onClick={clickHandler}
      sx={{
        zIndex: 1,
        position: "absolute",
        bottom: ["30px", "40px", "50px", "60px"],
        mx: "auto",
        left: 0,
        right: 0,
      }}
    >
      <Text variant="text.textSmall">
        {disabled ? "No data here!" : "Submit Query"}
      </Text>
    </Button>
  )
}

export default QueryButton
