import React from "react"

import { Flex, Heading, Text, ColumLayout } from "marketplace/app/components"

const LOADING_CONTENT = {
  title: "This process can take a little time.",
  subtitle: "Don't despair!",
}

const SearchLoadingCover = () => {
  const { title, subtitle } = LOADING_CONTENT

  return (
    <ColumLayout
      absolute
      centered
      overlay
      backgroundColor="rgba(211, 233, 239, 0.9)"
    >
      <Flex column sx={{ maxWidth: "500px", mb: "100px" }}>
        <Heading variant="text.loadingTitle">{title}</Heading>
        <Text variant="text.loadingSubtitle">{subtitle}</Text>
      </Flex>
    </ColumLayout>
  )
}

export default SearchLoadingCover
