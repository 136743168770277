import React from "react"

import { useSearchContext } from "../../context/SearchContext"
import {
  Flex,
  Heading,
  ButtonIcon,
  ButtonAction,
  ColumLayout,
} from "marketplace/app/components"
import SummaryTable from "./SummaryTable"
import CloseImg from "marketplace/app/images/icons/close.svg"

const Summary = () => {
  const { searchParams, dataset, askReset, addToCart } = useSearchContext()

  return (
    <ColumLayout absolute overlay backgroundColor="rgba(211, 233, 239, 0.9)">
      <Flex sx={{ mx: [null,null,"28px","28px"], mt: ["30px","30px","40px","60px"], mb: ["10px","10px","10px","60px"], justifyContent: "flex-end" }}>
        <ButtonIcon
          src={CloseImg}
          onClick={askReset}
          onCLick={()=> {}}
          alt="Close"
          title="Close"
        />
      </Flex>
      <Flex
        column
        sx={{
          mx: [null,null,"48px","48px"],
          height: "auto",
          // height: "100%",
          maxHeight: "460px",
          // overflowY: "auto",
        }}
      >
        <Heading variant="text.previewTitle" sx={{ mb: ["30px","30px","40px","50px"] }}>
          Dataset summary
        </Heading>
        <SummaryTable dataset={{...searchParams, ...dataset }} />
      </Flex>
      <Flex
        sx={{
          justifyContent: "center",
          my: ["20px","30px","40px","56px"],
        }}
      >
        <ButtonAction secondary onClick={addToCart}>
          Add to cart
        </ButtonAction>
      </Flex>
    </ColumLayout>
  )
}

export default Summary
