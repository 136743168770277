import React from "react"

import { IQueryParams } from "../types"
import { getEstimatedPointsDataset } from "../services"


export default function useQueryData() {
  const [data, setData] = React.useState(null)
  // const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(null)

  const fetchQueryData = async (queryParams: IQueryParams) => {
    // setFetching(true)

    try {
      const response = await getEstimatedPointsDataset(queryParams)
      setData(response)
      // setFetching(false)
    } catch (err) {
      // setFetching(false)
      setError(err)
    }
  }

  return {
    data,
    // fetching,
    error,
    fetchQueryData,
  }
}
