import React from "react"
import { FlyToInterpolator } from "react-map-gl"

import { IPreviewParams } from "marketplace/search/types"
import usePreviewData from "./usePreviewData"
import { checkResetBounds, getSpatialParamsFromMapBounds } from "../utils"

//

const initialViewport = {
  latitude: 20,
  longitude: 0,
  zoom: 0,
  maxZoom: 10,
}

const flyToWorldOptions = {
  ...initialViewport,
  transitionInterpolator: new FlyToInterpolator({ speed: 1.5 }),
  transitionDuration: "auto",
}

const initialSpatialParams = {
  lat1: 90,
  lng1: -180,
  lat2: -90,
  lng2: 180,
}


export default function useMap({
  status, resetFromMap, searchParams, updateSpatialParam
}) {
  const { time: timeParam } = searchParams

  const mapRef = React.useRef(null)
  const [viewport, setViewport] = React.useState(initialViewport)
  const [initialLoad, setInitialLoad] = React.useState(false)
  const [previewSpatialParam, setPreviewSpatialParam] = React.useState(
    initialSpatialParams
  )

  const editorRef = React.useRef(null)
  const [isDrawing, setIsDrawing] = React.useState(false)

  // 

  const heatmapQueryParams: IPreviewParams = {
    ...previewSpatialParam,
    startDate: timeParam.start,
    endDate: timeParam.end,
  }

  const {
    data: dataPreview,
    fetching: fetchingPreview,
    error: errorPreview,
    fetchPreviewData,
  } = usePreviewData(heatmapQueryParams)

  // 

  React.useEffect(() => {
    if (status === "reseting") {
      flyToWorld()
      resetFromMap()
    }
  }, [status])

  React.useEffect(() => {
    if (!initialLoad && dataPreview) {
      setInitialLoad(true)
    }
  }, [initialLoad, dataPreview])

  React.useEffect(() => {
    if (!initialLoad) return

    let timer = setTimeout(() => {
      updateHeatmap()
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [viewport])

  React.useEffect(() => {
    if (!dataPreview) return
    fetchPreviewData(heatmapQueryParams)
  }, [previewSpatialParam, timeParam])

  // 

  const flyToWorld = () => {
    setViewport({ ...flyToWorldOptions })
  }

  const updateHeatmap = () => {
    if (isDrawing) return

    const mapInstance = mapRef.current.getMap()
    const bounds = mapInstance.getBounds()

    if (checkResetBounds(bounds)) {
      flyToWorld()
      return
    }

    const features = editorRef.current && editorRef.current.getFeatures()
    const newHeatmapParam = getSpatialParamsFromMapBounds("heatmap", bounds)

    if (features && features.length > 0) {
      setPreviewSpatialParam(newHeatmapParam)

    } else {
      setPreviewSpatialParam(newHeatmapParam)
      updateSpatialParam(getSpatialParamsFromMapBounds("dataset", bounds))
    }
  }

  const viewportHandler = React.useCallback(newViewport => {
    setViewport(newViewport)
  }, [])

  return {
    mapRef,
    viewport,
    previewSpatialParam,
    dataPreview,
    fetchingPreview,
    errorPreview,
    initialLoad,
    editorRef,
    // 
    updateHeatmap,
    viewportHandler,
    flyToWorld,
    setIsDrawing,
  }
}