import React from "react"
import { Source, Layer } from "react-map-gl"

import heatmapGlobal from "./heatmapGlobal"
import heatmapMedium from "./heatmapMedium"
import heatmapDetail from "./heatmapDetail"

const HeatmapLayer = ({ data }) => (
  <Source id="search-preview-layer" type="geojson" data={data.geoJson}>
    {/* <Layer type="heatmap" /> */}
    {data.level === 5 && <Layer {...heatmapGlobal(data.stats)} />}
    {data.level === 8 && <Layer {...heatmapMedium(data.stats)} />}
    {data.level === 11 && <Layer {...heatmapDetail(data.stats)} />}
  </Source>
)

export default React.memo(HeatmapLayer)
