/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect } from "react"

const PLACEHOLDER_TEXT = "Search by location..."

// onChange trigger function
function triggerInput(enteredName, enteredValue) {
  const input = document.getElementById(enteredName)
  // const lastValue = input.value;
  input.value = enteredValue
  const event = new Event("input", { bubbles: true })
  // const tracker = input._valueTracker;
  // if (tracker) {
  //   tracker.setValue(lastValue);
  // }
  input.dispatchEvent(event)
}

const GeocoderInput = props => {
  const [placeholder, setPlaceholder] = React.useState(PLACEHOLDER_TEXT)

  useEffect(() => {
    if (props.resetflag === "reset") {
      triggerInput("geocoderInput", null)
    }
  }, [props.resetflag])

  const focusHandler = e => {
    if (placeholder && placeholder !== "") {
      setPlaceholder("")
    }
    props.onFocus(e)
  }

  const blurHandler = e => {
    if (!placeholder || placeholder === "") {
      setPlaceholder(PLACEHOLDER_TEXT)
    }
    props.onBlur(e)
  }

  return (
    <input
      {...props}
      id="geocoderInput"
      placeholder={placeholder}
      value={props.resetflag === "reset" ? "" : props.value || ""}
      // onChange={changeHandler}
      onFocus={focusHandler}
      onBlur={blurHandler}
      sx={{
        width: "auto",
        outline: "none",
        "&::placeholder": {
          opacity: 1,
          color: "text",
        },
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
  )
}

export default GeocoderInput
