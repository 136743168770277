import React from "react"
import MapGL from "react-map-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"

const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN
const MAPBOX_BASEMAP_URL = process.env.GATSBY_MAPBOX_BASEMAP_URL


const BaseMap = ({ mapRef, viewport, viewportHandler, children }) => {
  return (
    <MapGL
      {...viewport}
      ref={mapRef}
      width="100%"
      height="100%"
      mapStyle={MAPBOX_BASEMAP_URL}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      attributionControl={false}
      preventStyleDiffing={true}
      // CALLBACKS
      onViewportChange={viewportHandler}
      // INTERACTION OPTS
      doubleClickZoom={false}
    >
      {children}
    </MapGL>
  )
}

export default BaseMap
