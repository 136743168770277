import React from "react"
import { NavigationControl, GeolocateControl } from "react-map-gl"

const MapControls = () => {
  return (
    <>
      <GeolocateControl
        auto
        trackUserLocation={true}
        positionOptions={{ enableHighAccuracy: true }}
        style={{
          right: 20,
          bottom: 100,
          boxShadow: "0px 2px 4px rgba(37, 37, 37, 0.24)",
          backgroundColor: "#fff"
        }}
      />
      <NavigationControl
        showCompass={false}
        style={{
          right: 20,
          bottom: 30,
          boxShadow: "0px 2px 4px rgba(37, 37, 37, 0.24)",
          backgroundColor: "#fff"
        }}
      />
    </>
  )
}

export default MapControls
