import React from "react"

import { IPreviewParams } from "marketplace/search/types"
import { getPreviewData } from "../services"


export default function usePreviewData(queryParams: IPreviewParams) {
  const [data, setData] = React.useState(null)
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    fetchPreviewData(queryParams)
  }, [])

  const fetchPreviewData = async (queryParams: IPreviewParams) => {
    setFetching(true)

    try {
      const response = await getPreviewData(queryParams)
      setData(response)
    } catch (err) {
      setError(err)
    } finally {
      setFetching(false)
    }
  }

  return {
    data,
    fetching,
    error,
    fetchPreviewData,
  }
}
