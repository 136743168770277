import { extent, quantile } from 'simple-statistics'

import { http } from "marketplace/app/http/http"
import { API } from "marketplace/app/config"
import { IPreviewDataset, IPreviewDatasetResponseDTO, IPreviewParams } from "marketplace/search/types"

import { PreviewDataError } from "./errors"


/**
 * 
 * Utility to calculate dataset stats
 * TODO: move processing to backend ?
 * 
 */

function calculateDatasetStats(features) {
  const dataWeight = features.map(f => f.properties.weight)
  const dataInterval = extent(dataWeight)
  return {
    min: dataInterval[0],
    max: dataInterval[1],
    p90: quantile(dataWeight, 0.9),
    p95: quantile(dataWeight, 0.95),
    p99: quantile(dataWeight, 0.99),
  }
}

function prepareOnePointDataset(response) {
  return {
    ...response.result,
    geoJson: {
      ...response.result.geoJson,
      features: [
        // add one fake point close to 0
        {
          "type": "Feature",
          "geometry": {
            "type": "Point",
            "coordinates": [
              0,
              0
            ]
          },
          "properties": {
            "count": 1,
            "weight": 0.001
          },
        },
        ...response.result.geoJson.features,
      ]
    },
    stats: {
      min: 0.0001,
      max: 1,
      p90: 0.1,
      p95: 0.4,
      p99: 1
    }
  }
}

/**
 * Service to request heatmap data to backend data API
 * Uses http abstraction (Fetch API, Axios..) and preprocess the response
 */

export async function getPreviewData(queryParams: IPreviewParams): Promise<IPreviewDataset> {
  try {
    const response = await http.post<IPreviewDatasetResponseDTO>(API.heatmap, queryParams)

    if (!response.result) throw new Error()

    const { features } = response.result.geoJson

    if (!features) return response.result

    if (features.length === 1) {
      return prepareOnePointDataset(response)
    }

    return {
      ...response.result,
      stats: calculateDatasetStats(features)
    }
  } catch (err) {
    throw new PreviewDataError()
  }

}
