import { http } from "marketplace/app/http/http"
import { API } from "marketplace/app/config"
import { IQueryParams, IQueryDataset } from "./types"
import { EstimatedPointsError } from "./errors"


export async function getEstimatedPointsDataset(queryParams: IQueryParams): Promise<IQueryDataset> {
  try {
    const response = await http.post<IQueryDataset>(API.points, queryParams)
    if (!response.numberOfDataPoints) throw new Error()
    return response
  } catch (err) {
    throw new EstimatedPointsError()
  }
}
