import { State, Action } from "./types"
import initialState from "./initialState"

/**
 * 
 * This reducer resolves the state of the search module 
 * (status, params and dataset) for the defined actions. 
 * 
 */

export default function searchReducer(state: State, action: Action): State {
  switch (action.type) {
    case "UPDATE_SPATIAL_PARAM":
      return {
        ...state,
        params: {
          ...state.params,
          spatial: action.payload
        }
      }

    case "UPDATE_TIME_PARAM":
      return {
        ...state,
        params: {
          ...state.params,
          time: action.payload
        }
      }

    case "SUBMIT":
      return {
        ...state,
        status: "pending"
      }

    case "ADD_TO_SEARCH":
      return {
        ...state,
        status: "summary",
        dataset: action.payload
      }

    case "ADDED_TO_CART":
      return {
        ...state,
        status: "confirmed"
      }

    case "ASK_RESET":
      return {
        ...state,
        status: "reseting"
      }

    case "RESET_FROM_MAP":
      // TODO:
      return {
        ...initialState
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}
