/**
 * 
 */
export class PreviewDataError extends Error {
  constructor(type = "error") {
    super()

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PreviewDataError)
    }

    this.name = "PreviewDataError"
    this.date = new Date()

    this.type = type

    this.message = "There has been an error fetching preview data."
  }
}