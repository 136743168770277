import React from "react"

import { useSearchContext } from "../../context/SearchContext"
import {
  Heading,
  ButtonAction,
  ButtonLink,
  BottomWrapper,
  ColumLayout,
} from "marketplace/app/components"

const AddedToCart = () => {
  const { askReset } = useSearchContext()

  return (
    <ColumLayout absolute centered overlay backgroundColor="rgba(0, 221, 194, 0.8)">
      <Heading variant="text.previewTitle" sx={{ mb: "50px", textAlign: "center" }}>
        Dataset added to Cart!
      </Heading>
      {/* Buttons wrapper */}
      <BottomWrapper>
        <ButtonAction secondary onClick={askReset} sx={{ mr: [null,"16px","16px","16px"], mb: ["16px", "unset", "unset", "unset"] }}>
          Continue Shopping
        </ButtonAction>
        <ButtonLink to="/cart">Go to cart</ButtonLink>
      </BottomWrapper>
    </ColumLayout>
  )
}

export default AddedToCart
