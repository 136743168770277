import React from "react"

import { State, Dispatch, SearchProviderProps } from "./types"
import initialState from "./initialState"
import searchReducer from "./reducer"

import { useContract } from "marketplace/web3"
import { useCart } from "marketplace/cart"

import { IQueryParams } from "../types"
import useQueryData from "../hooks/useQueryData"

/**
 *
 * Search context, used as reducer store
 *
 */

const SearchContext = React.createContext<{
  state: State
  dispatch: Dispatch
} | null>(null)

SearchContext.displayName = "SearchContext"

/**
 *
 * Hook to use the search context
 *
 */

function useSearchContext() {
  const context = React.useContext(SearchContext)

  if (!context) {
    throw new Error("useSearchContext must be used within a SearchProvider")
  }

  return context
}

/**
 *
 * Search context provider, using a reducer store
 * to keep track of the search status, dataset query params
 * and the dataset of interest (preorder)
 *
 */

function SearchProvider({ children }: SearchProviderProps) {
  const [state, dispatch] = React.useReducer(searchReducer, initialState)
  const { status, params, dataset } = state

  const {
    data: dataQuery,
    // fetching: fetchingQuery,
    error,
    fetchQueryData,
  } = useQueryData()

  const { estimateDatasetPrice } = useContract()
  const { cart, addDatasetToCart } = useCart()

  React.useEffect(() => {
    if (dataQuery) {
      const numPoints = parseInt(dataQuery.numberOfDataPoints)
      getEstimatedPrice(numPoints)
    }
  }, [dataQuery])

  React.useEffect(() => {
    if (cart.length && dataset) {
      confirmAddedToCart()
    }
  }, [cart])

  //

  const updateSpatialParam = newParam => {
    dispatch({
      type: "UPDATE_SPATIAL_PARAM",
      payload: newParam,
    })
  }

  const updateTimeParam = newDateRange => {
    dispatch({
      type: "UPDATE_TIME_PARAM",
      payload: newDateRange,
    })
  }

  const submitQuery = () => {
    dispatch({ type: "SUBMIT" })
  }

  const confirmSubmit = () => {
    const queryParams: IQueryParams = {
      polygon: params.spatial,
      startDate: params.time.start,
      endDate: params.time.end,
    }
    fetchQueryData(queryParams)
    submitQuery()
  }

  const getEstimatedPrice = async numPoints => {
    try {
      const estimatedPrice = await estimateDatasetPrice(numPoints)
      const summaryDate = new Date()
      addToSearch({ numPoints, estimatedPrice, summaryDate })
    } catch (err) {
      console.error("Error getEstimatedPrice, useSearchContext", err)
    }
  }

  const addToSearch = ({ numPoints, estimatedPrice, summaryDate }) => {
    dispatch({
      type: "ADD_TO_SEARCH",
      payload: {
        grossPoints: numPoints,
        grossPrice: estimatedPrice,
        // grossPrice: parseInt(estimatedPrice),
        summaryDate,
        name: `Dataset ${summaryDate.getTime()}`,
      },
    })
  }

  const addToCart = () => {
    addDatasetToCart({ ...dataset, params })
  }

  const confirmAddedToCart = () => {
    dispatch({ type: "ADDED_TO_CART" })
  }

  const askReset = () => {
    dispatch({ type: "ASK_RESET" })
  }

  const resetFromMap = () => {
    dispatch({ type: "RESET_FROM_MAP" })
  }

  const value = {
    // state
    status,
    searchParams: params,
    dataset,
    error,
    updateSpatialParam,
    updateTimeParam,
    confirmSubmit,
    addToCart,
    askReset,
    resetFromMap,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

export { SearchProvider, useSearchContext }
