import React from "react"
import { useErrorHandler } from "react-error-boundary"

import { useWallet } from "marketplace/web3"
import { MetamaskButton, ConfirmationModal } from "marketplace/app/components"
import QueryButton from "./QueryButton"
import { useSearchContext } from "../../context/SearchContext"

const buttonStyle = {
  zIndex: 1,
  position: "absolute",
  bottom: "60px",
  mx: "auto",
  left: 0,
  right: 0,
}

const CONFIRMATION_CONTENT = {
  title: "Pay attention!",
  description:
    "You are going to request an estimated budget as well as the size of the corresponding dataset.",
  // "If you continue without adding location or date range, the query will be of all available data. Confirm if this is what you want.",
  button: "Confirm",
}

const Form = ({ noData }) => {
  const { active, connect, walletError } = useWallet()

  const handleError = useErrorHandler()

  React.useEffect(() => {
    if (walletError) handleError(walletError)
  }, [walletError])

  const { confirmSubmit } = useSearchContext()

  const [isOpen, setIsOpen] = React.useState(false)
  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const submitHandler = () => {
    closeDialog()
    confirmSubmit()
  }

  return (
    <>
      {active ? (
        <QueryButton disabled={noData} clickHandler={openDialog} />
      ) : (
        <MetamaskButton
          clickHandler={() => connect("metamask")}
          dark
          sx={buttonStyle}
        />
      )}
      <ConfirmationModal
        content={CONFIRMATION_CONTENT}
        isOpen={isOpen}
        closeDialog={closeDialog}
        submitHandler={submitHandler}
      />
    </>
  )
}

export default Form
